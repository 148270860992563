.main {
    margin-top: 270px;
    margin-left: 60px;
    margin-right: 60px;
    padding: 30px;
}

.about_us, .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-bottom: 50px;
}

.aboutUs_content {
    background-color: rgb(246, 62, 62);
    box-shadow: 2px, 2px, 2px, 1px, rgba(0, 0, 0, 0.7);
    color: black;
    padding: 10px;
    margin: 20px;
    border-radius: 3px;

}

.about_title, .services_title {
    color: rgb(43, 43, 43);
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;

}

.aboutUs_text {
    color: rgb(219, 215, 215);
    font-family: 'Times New Roman', Times, serif;
    font-size: 28px;
    margin: 10px;
   text-align: center;
}


.production, .marketing {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    font-family: 'Times New Roman', Times, serif;
}

.description {
    margin: 25px;
    flex: 0.6;
}

.descript_title {
    margin-bottom: 10px;
    color: red;
    font-size: 28px;
}

.descript_text {
    font-size: 20px;
}

.service_img {
    object-fit: contain;
    flex: 0.4;
}

@media (max-width: 780px) {
.main {
    margin-left: 10px;
    margin-right: 10px;
}

.aboutUs_content {
        margin-left: 8px;
        margin-right: 8px;
    }

.about_title, .services_title {
        margin-top: 10px;
    }



.aboutUs_text {
        font-size: 20px;
    }
.production, .marketing {
    flex-direction: column;
    text-align: center;
}

.service_img {
    height: 250px;
    flex: 0.2;
}

.description {
    flex: 0.8;
}
}