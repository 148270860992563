.foot_section {
    display: flex;
    justify-content: center;
    height: 10vh;
    padding: 10px;
    align-items: center;
    background-color: rgba(203, 203, 203, 0.876);
    position: sticky;
    z-index: 100;
}

.insta, .youtube, .linkedIn {
    height: 50px;
    margin: 15px;
}

@media (max-width: 780px) {
    .foot_section {
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 6vh;
    }
}