.hero {
    align-items: center;
}

.mission_statement {

    color: rgb(30, 29, 29);
    margin-top: 80px;
    margin-left: 40px;
    margin-right: 40px;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    position: absolute;
    z-index: 2;
}

.hero_image {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    z-index: -1;
    margin-bottom: -300px;
    mask-image: linear-gradient( rgba(0, 0, 0, 1), transparent);
}

@media (max-width: 780px) {
    .mission_statement {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 8px;
    }
}

